<template>
  <div>
    <teleport selector="#appButton">
      <b-dropdown
        aria-role="list"
        position="is-bottom-left"
        append-to-body
      >
        <template #trigger>
          <b-button
            icon-left="playlist-edit"
            class="px-6 add-new-btn btn-primary-light top-btn"
          >
            Table Actions
          </b-button>
        </template>
        <b-dropdown-item
          aria-role="listitem"
          @click="goToStructure"
        >
          <b-icon
            size="is-small"
            class="mr-1"
            icon="database-cog"
          />
          <span class="ml-2">Table Structure</span>
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          @click="downloadTable"
        >
          <b-icon
            size="is-small"
            class="mr-1"
            icon="file-excel-outline"
          />
          <span class="ml-2">Export Table</span>
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          @click="showNewRecordModal"
        >
          <b-icon
            size="is-small"
            class="mr-1"
            icon="plus"
          />
          <span class="ml-2">New Record</span>
        </b-dropdown-item>
      </b-dropdown>
    </teleport>

    <div class="py-4 pb-6">
      <div class="table-container nuclicore-table-content">
        <b-table
          class="table is-bordered is-narrow is-fullwidth"
          backend-filtering
          backend-sorting
          :data="records"
          striped
          :paginated="true"
          :per-page="20"
          :sticky-header="true"
          :backend-pagination="true"
          :total="totalRecords"
          @page-change="handlePageChange"
          @filters-change="backendCustomSearch"
          @sort="backendSort"
        >
          <b-table-column
            v-for="(columnObj, index) in columns"
            :key="index"
            v-slot="{ row }"
            :label="columnObj.label"
            :field="columnObj.field"
            :searchable="columnObj.searchable"
            :sortable="true"
          >
            {{ !isAdmin && columnObj.type === 'PASSWORD' ? maskPassword(row[columnObj.field]) : row[columnObj.field] }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            field="action"
            label="Actions"
            :sticky="true"
            width="60"
          >
            <b-button
              icon-left="pencil"
              size="is-small"
              class="mr-2"
              type="is-secondary is-light"
              @click="editRecord(row.id)"
            />
            <b-button
              icon-left="delete"
              size="is-small"
              type="is-danger is-light"
              @click="deleteRecord(row.id)"
            />
          </b-table-column>


          <template #empty>
            <div class="has-text-centered">
              No records
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <BaseModal
      v-model="editRecordModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Edit record"
      aria-modal
    >
      <CardPopup
        title="Edit record"
        class="w-800"
        @hide="editRecordModalActive = false"
      >
        <template #body>
          <b-field
            v-for="(singleColumn, index) in columnList"
            :key="index"
            :label="singleColumn.label"
          >
            <b-input
              v-if="!isAdmin && singleColumn.type === 'PASSWORD'"
              :value="maskPassword(singleRecord[singleColumn.column_id])"
              :disabled="!isAdmin && singleColumn.type === 'PASSWORD'"
            />
            <b-input
              v-else
              v-model="singleRecord[singleColumn.column_id]"
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="editRecordModalActive = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="updateRecord(singleRecord['id'])"
            >
              Update
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>

    <BaseModal
      v-model="newRecordModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New record"
      aria-modal
    >
      <CardPopup
        title="Add record"
        class="w-800"
        @hide="newRecordModalActive = false"
      >
        <template #body>
          <b-field
            v-for="(singleColumn, index) in columnList"
            :key="index"
            :label="singleColumn.label"
            :type="{ 'is-danger': columnName === singleColumn.column_id }"
            :message="columnName === singleColumn.column_id ? sqlMsg ? sqlMsg : 'Incorrect value' : ''"
          >
            <b-input
              v-model="recordRequest[singleColumn.column_id]"
              @input="handleChange($event)"
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="newRecordModalActive = false, columnName = '', sqlMsg = ''"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="addRecord()"
            >
              Insert
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
import { defineAsyncComponent, onMounted, ref, watch, computed } from '@vue/composition-api';
import json2csv from 'json2csv';
import { createRecordService, fetchRecordsService, deleteRecordService, updateRecordService, downloadRecordsService } from '@/services/database-service/recordRequests';
import { fetchTableByIdService } from '@/services/database-service/tableRequests';
import { getColumnsService } from '@/services/database-service/columnRequests';
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { arrayToObject } from '@/helpers/util';
import { useBuefy } from '@/hooks/buefy';
import { useLocalDatabase } from '@/modules/builder/components/module-sidebar/action/local-database/localDatabase';
import { useSession } from '@/hooks/vueSession';

//-- child components --//
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));

//-- composable hooks --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const router = useRouter();
  const buefy = useBuefy();
  const session = useSession();

  //-- table logic --//
  const {
    isColumnPartOf,
    isDefaultDateTypeColumn
  } = useLocalDatabase();
  const tableName = ref('');
  const columnName = ref('');
  const sqlMsg = ref('');
  const isAdmin = computed(() => {
    return session.get('role') === 'admin';
  });
  const maskPassword = password => {
    const length = password?.length;
    return !length ? '' : '*'.repeat(length);
  };
  const goToStructure = () => {
    router.push('/application/' + route.params.appId + '/database/' + route.params.databaseId + '/table/' + route.params.tableId + '/structure');
  };
  const backendCustomSearch = async columns => {
    columns = Object.fromEntries(Object.entries(columns).filter(([key, value]) => value !== ''));
    filterRecords.value = {
      columns,
      sort_by: filterRecords.value.sort_by
    };
    await fetchRecords();
  };
  const backendSort = async (column, order) => {
    filterRecords.value = {
      columns: filterRecords.value.columns,
      sort_by: {
        [column]: order
      }
    };
    await fetchRecords();
  };
  const downloadTable = async () => {
    try {
      const response = await downloadRecordsService({
        query: {
          database: {
            name: route.params.databaseId
          },
          table: {
            name: route.params.tableId
          },
          condition: {},
          filter: filterRecords.value
        },
        maskPassword: !isAdmin.value
      });
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const {
        data: {
          data
        }
      } = await fetchTableByIdService(route.params.databaseId, route.params.tableId);
      link.download = `${data.TABLE_LABEL}.csv`;
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log('downloadTable: ', error);
    }
  };

  /**
   * @deprecated: Will be removed upcoming release
   */
  const exportTable = () => {
    const tableData = records.value.map(record => {
      const {
        Actions,
        ...restRecord
      } = record;
      return restRecord;
    });
    // convert json to csv
    const parser = new json2csv.Parser();
    const csv = parser.parse(tableData);
    // downoad csv file
    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', tableName.value);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  onMounted(async () => {
    await fetchColumns();
    await fetchRecords();
  });
  const handleChange = event => {
    if (event) {
      columnName.value = '';
      sqlMsg.value = '';
    }
  };

  //-- columns logic --//
  const column = ref([]);
  const columns = ref([]);
  const rawColumns = ref([]);
  const templateColumn = ref([]);
  const columnList = ref([]);
  const resetColumns = () => {
    column.value = [];
    templateColumn.value = [];
    columns.value = [];
  };
  const fetchColumns = async () => {
    try {
      const {
        data: {
          data
        }
      } = await getColumnsService(route.params.databaseId, route.params.tableId);
      rawColumns.value = data;
      templateColumn.value = data.map(column => ({
        column_id: column.column_name,
        label: column.column_label,
        value: ''
      })).filter(column => !isColumnPartOf(column.column_id));
      columns.value = data.map(column => ({
        field: column.column_name,
        label: column.column_label,
        type: column.column_type,
        searchable: true
      }));
      column.value = templateColumn.value;
      tableName.value = route.params.tableId;
      // for row edit
      columnList.value = data.filter(column => column.column_name !== 'id' && !isDefaultDateTypeColumn(column)).map(column => ({
        column_id: column.column_name,
        label: column.column_label,
        value: '',
        type: column.column_type
      }));
      console.log({
        columnList
      });
    } catch (err) {
      console.error(err);
    }
  };

  //-- records logic --//
  const records = ref([]);
  const recordRequest = ref({});
  const singleRecord = ref({});
  const newRecordModalActive = ref(false);
  const editRecordModalActive = ref(false);
  const filterRecords = ref({});
  const totalRecords = ref(0);
  const page = ref(1);
  watch(() => newRecordModalActive.value, () => {
    if (!newRecordModalActive.value) {
      recordRequest.value = {};
    }
  });
  watch(() => editRecordModalActive.value, () => {
    if (!editRecordModalActive.value) {
      singleRecord.value = {};
    }
  });
  const showNewRecordModal = () => {
    resetColumns();
    fetchColumns();
    newRecordModalActive.value = true;
  };
  const fetchRecords = async () => {
    try {
      const response = await fetchRecordsService({
        query: {
          database: {
            name: route.params.databaseId
          },
          table: {
            name: route.params.tableId
          },
          condition: {},
          filter: filterRecords.value,
          page: page.value,
          limit: 20
        }
      });
      totalRecords.value = response.data.total_count;
      let recordRawResponse = response.data.data;
      records.value = recordRawResponse.map(record => arrayToObject(record));
      for (let i = 0; i < records.value.length; i++) {
        Object.keys(records.value[i]).forEach(recordKey => {
          const column = rawColumns.value.find(col => recordKey === col.column_name);
          if (column && column.column_type === 'DECIMAL NUMBER / FLOAT' && typeof records.value[i][recordKey] === 'string') {
            // decimal values are parsed as string by backend, so parse them back to float
            records.value[i][recordKey] = parseFloat(records.value[i][recordKey]);
          }
        });
        records.value[i]['Actions'] = '';
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @param {string} id
   */
  const deleteRecord = id => {
    buefy.dialog.confirm({
      title: 'Deleting record',
      message: '<div style="padding: 1em;">Are you sure you want to <b>delete</b> this record?</div>',
      confirmText: 'Yes',
      cancelText: 'No',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        try {
          await deleteRecordService({
            query: {
              database: {
                name: route.params.databaseId
              },
              table: {
                name: route.params.tableId
              },
              condition: {
                operator: 'AND',
                conditions: [{
                  table: route.params.tableId,
                  column: 'id',
                  value: id
                }]
              }
            }
          });
          buefy.toast.open('Record deleted!');
          await fetchRecords();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  /**
   * @param {string} id 
   */
  const editRecord = async id => {
    try {
      const response = await fetchRecordsService({
        query: {
          database: {
            name: route.params.databaseId
          },
          table: {
            name: route.params.tableId
          },
          condition: {
            operator: 'AND',
            conditions: [{
              table: route.params.tableId,
              column: 'id',
              value: id
            }]
          }
        }
      });
      singleRecord.value = response.data.data[0];
      editRecordModalActive.value = true;
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @param {string} id 
   */
  const updateRecord = async id => {
    try {
      let data = {
        records: []
      };
      for (const key in singleRecord.value) {
        if (key === 'id' || isDefaultDateTypeColumn(rawColumns.value.find(v => v.column_name === key))) continue;
        data.records.push({
          table: route.params.tableId,
          column: key,
          value: singleRecord.value[key]
        });
      }
      await updateRecordService({
        query: {
          database: {
            name: route.params.databaseId
          },
          table: {
            name: route.params.tableId
          },
          condition: {
            operator: 'AND',
            conditions: [{
              table: route.params.tableId,
              column: 'id',
              value: id
            }]
          }
        }
      }, data);
      await fetchRecords();
      editRecordModalActive.value = false;
    } catch (err) {
      console.error(err);
    }
  };
  const addRecord = async () => {
    try {
      await createRecordService({
        database: {
          name: route.params.databaseId
        },
        table: {
          name: route.params.tableId
        },
        records: [recordRequest.value]
      });
      column.value = templateColumn.value;
      await fetchRecords();
      newRecordModalActive.value = false;
    } catch (err) {
      console.error(err);
      columnName.value = err?.response?.data?.column_name;
      sqlMsg.value = err?.response?.data?.message;
    }
  };
  const handlePageChange = async currentPage => {
    page.value = currentPage;
    await fetchRecords();
  };
  return {
    columnName,
    sqlMsg,
    isAdmin,
    maskPassword,
    goToStructure,
    backendCustomSearch,
    backendSort,
    downloadTable,
    handleChange,
    columns,
    columnList,
    records,
    recordRequest,
    singleRecord,
    newRecordModalActive,
    editRecordModalActive,
    totalRecords,
    showNewRecordModal,
    deleteRecord,
    editRecord,
    updateRecord,
    addRecord,
    handlePageChange
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">

.nuclicore-table-content {
  font-size: 0.75rem;
}


.nuclicore-table-content .button {
  padding: 0 0.5em;
  line-height: 1em;
  height: 1.5em;
}
</style>

<style lang="scss">
@import '~@/style/components.scss';
.level {
  overflow: hidden;
}
</style>