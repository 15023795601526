// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { DATABASE_SERVICE_ENDPOINT, RECORD_RESOURCE_PATH, RECORD_DOWNLOAD_RESOURCE_PATH } from './databaseServiceConstants';

const recordServiceConfig = createHttpServiceConfig(DATABASE_SERVICE_ENDPOINT, RECORD_RESOURCE_PATH);

//-- request interceptors --//
recordServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {import('../../types/databaseTypes').INewRecord} data
 */
export const createRecordService = (data) => recordServiceConfig.http({
    method: 'POST',
    data
});

/**
 * @param {import('./databaseServiceTypes').IFetchRecordsParams} params
 */
export const fetchRecordsService = (params) => recordServiceConfig.http({
    method: 'GET',
    params: {
        ...params,
        query: JSON.stringify(params.query)
    }
});

/**
 * @param {import('./databaseServiceTypes').IFetchRecordsParams} params
 */
export const downloadRecordsService = (params) => recordServiceConfig.http({
    method: 'GET',
    url: `${RECORD_DOWNLOAD_RESOURCE_PATH}`,
    params: {
        ...params,
        query: JSON.stringify(params.query)
    }
});

/**
 * @param {import('./databaseServiceTypes').IUpdateRecordParams} params 
 * @param {import('../../types/databaseTypes').IUpdateRecord} data
 */
export const updateRecordService = (params, data) => recordServiceConfig.http({
    method: 'PATCH',
    params,
    data
});

/** 
 * @param {import('./databaseServiceTypes').IDeleteRecordParams} params 
 */
export const deleteRecordService = (params) => recordServiceConfig.http({
    method: 'DELETE',
    params
});
